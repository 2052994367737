import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    padding: theme.spacing(4),
  },
  headerRoot: {
    paddingBottom: 32,
  },
  header: {
    fontWeight: 'bold',
    marginBottom: 8,
  },
  column: {
    textAlign: 'right',
  },
  content: {
    padding: 40,
    maxWidth: 400,
    minHeight: 620,
    display: 'flex',
    borderRadius: 20,
    flexDirection: 'column',
    backgroundColor: theme.palette.dark,
    marginTop: 16,
  },
  note: {
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
  noteText: {
    textAlign: 'justify',
    fontSize: 12,
    color: theme.palette.secondary.main,
  },
  lightButton: {
    [theme.breakpoints.up('md')]: {
      fontSize: '1',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '.75rem',
    },
    margin: 'auto',
    border: 'solid 2px #fff',
    borderRadius: 10,
    padding: '8px 0',
    fontWeight: 'bolder',
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.dark,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover svg': {
      position: 'relative',
      animationName: '$slide',
      animationDuration: '0.8s',
      animationTimingFunction: 'linear',
      animationIterationCount: '8',
    },
  },
  '@keyframes slide': {
    '0%, 100%': { left: 0 },
    '50%': { left: 4 },
  },
  max: {
    color: theme.palette.secondary.dark,
  },
  balance: {
    fontSize: 14,
    marginTop: 8,
    marginBottom: 12,
    alignSelf: 'flex-end',
  },
  balanceValue: {
    color: '#bc9c1b',
  },
  pkr: {
    padding: '10px 12px',
    fontWeight: 'bold',
    backgroundColor: theme.palette.background.default,
    color: 'rgba(194, 195, 197, 0.65)',
    textAlign: 'center',
    lineHeight: 2,
    borderRadius: 10,
  },
  splitter: {
    color: theme.palette.secondary.dark,
  },
  inputRoot: {
    display: 'flex',
    minWidth: 60,
    borderRadius: 10,
    alignItems: 'center',
    paddingLeft: 16,
    backgroundColor: theme.palette.background.default,
  },
  input: {
    flex: 1,
    margin: '8px 0',
  },
  arrowIcon: {
    width: 30,
    height: 30,
    padding: 2,
    margin: '16px 0',
    textAlign: 'center',
    borderRadius: '50%',
    alignSelf: 'center',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  button: {
    minWidth: '100%',
    marginTop: 16,
    fontSize: 16,
    padding: '8px 0',
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.main,
  },
  hash: {
    marginTop: 8,
    fontSize: 12,
    zIndex: 20,
  },
  connectedWallet: {
    color: theme.palette.secondary.dark,
    marginTop: 32,
  },
  inputLabel: {
    color: theme.palette.secondary.dark,
  },
  dealerRoot: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    marginTop: 32,
    justifyContent: 'center',
    textAlign: 'center',
    zIndex: 1,
    width: '100%',
  },
  dealsHeader: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '.75rem',
    },
    marginRight: 8,
  },
  circle: {
    height: 275,
    width: 275,
    borderRadius: 200,
    border: 'solid 2px #fff',
    margin: '0px auto',
    color: theme.palette.secondary.main,
    position: 'relative',
    textAlign: 'center',
  },
  circleContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    margin: 'auto',
  },
  line: {
    maxWidth: 56,
    height: 60,
    borderBottom: '1px solid #fff',
    margin: 'auto',
  },
  dealHeader: {
    marginTop: 14,
    marginRight: -10.8,
    letterSpacing: 10.8,
    fontSize: 18,
    color: theme.palette.secondary.dark,
  },
  dealerContainer: {
    width: '100%',
    display: 'flex',
    minHeight: 320,
    borderRadius: 8,
    margin: '24px auto',
    padding: '16px 24px',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: 'rgba(33, 33, 33, 0.7)',
  },
  dealerCard: {
    borderRadius: 15,
    padding: '20px 30px',
  },
  imageRoot: {
    position: 'relative',
  },
  networkSwitch: {
    flex: 1,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
  },
  walletRoot: {
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    zIndex: 1,
    width: '100%',
    paddingTop: 40,
    justifyContent: 'space-between',
  },
  radioButton: {
    top: 0,
    left: 0,
    margin: 0,
    opacity: 0,
    width: '100%',
    cursor: 'pointer',
    position: 'absolute',
    height: '100%',
    zIndex: 8,
    '&:checked + div > div > div > $buttonRoot': {
      display: 'flex',
      zIndex: 100,
    },
    '&:checked + div img': {
      filter: 'brightness(60%)',
    },
  },
  withdrawPrice: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    // position: 'relative',
  },
  priceRoot: {
    position: 'absolute',
    zIndex: 7,
    bottom: 4,
    width: '100%',
  },
  radioContainer: {
    position: 'relative',
    padding: '24px 16px',
  },
  usd: {
    fontSize: 29,
    fontWeight: 'bold',
  },
  approx: {
    fontSize: 18,
    marginLeft: 4,
    color: '#e5b839',
  },
  from: {
    fontSize: 14,
    display: 'flex',
    justifyContent: 'center',
  },
  reward: {
    fontSize: 14,
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  price: {
    fontSize: 14,
    textDecoration: 'line-through',
    marginLeft: '5px',
  },
  dealerContent: {
    width: '100%',
  },
  logoutButton: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '.75rem',
    },
    color: '#e5b839',
  },
  noteRoot: {
    width: '100%',
    justifyContent: 'end',
    zIndex: 1,
  },
  center: {
    fontSize: 20,
    fontWeight: 200,
    borderRadius: 30,
    zIndex: 1,
    margin: 0,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  walletContainer: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 8,
    },
    display: 'flex',
    alignItems: 'center',
  },
  displayWallet: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '.75rem',
    },
  },
  passInfoRoot: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 8,
    },
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  loading: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  loadingIcon: {
    marginRight: 8,
  },
  balanceInfoRoot: {
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    width: 320,
  },
  buttonRoot: {
    top: 0,
    width: '100%',
    height: '100%',
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 1,
  },
  withdrawDealer: {
    zIndex: 100,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 24,
    fontWeight: 'bold',
    border: `2px solid ${theme.palette.primary.main}`,
  },
  syncButton: {
    backgroundColor: theme.palette.primary.main,
  },
  depositDealerCard: {
    minHeight: 400,
    minWidth: 280,
    backgroundColor: '#e3c5d517',
    borderRadius: 8,
  },
  depositRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tokenField: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  mobileView: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    width: '100%',
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  depositHeader: {
    marginTop: 40,
    color: theme.palette.secondary.dark,
  },
  depositButton: {
    height: 44,
    marginLeft: 8,
    borderColor: theme.palette.primary.main,
    border: '2px solid',
    borderRadius: 30,
    fontWeight: '800',
  },
  rowHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  placeholder: {
    minWidth: 340,
  },
  inputStyle: {
    border: '1px solid',
    borderRadius: 8,
    padding: 12,
    '&:hover, &:active, &:focus': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 80,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 68,
    },
  },
  modalContainer: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    margin: 'auto',
    backgroundColor: '#4a3d42',
    padding: 24,
    borderRadius: 8,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 12,
  },
  cancelButton: {
    backgroundColor: theme.palette.primary.main,
    marginRight: 8,
  },
  backdrop: {
    position: 'absolute',
    backgroundColor: 'black',
    opacity: 0.6,
    height: '100%',
    width: '100%',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  dealerName: {
    position: 'absolute',
    color: 'white',
    bottom: '50%',
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: 32,
  },
  back: {
    marginRight: 8,
  },
  dialogTitle: {
    marginBottom: 16,
  },
  depositPaper: {
    maxWidth: 740,
    borderRadius: 4,
    padding: '24px 32px',
  },
  dialogButton: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default useStyles;
