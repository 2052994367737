export const NFT = [
  {
    id: 0,
    name: 'Iris',
    location: 'Cape Town, South Africa',
    description:
      'She is sensational-looking. She must be in her thirties, but she dresses young, trendily, and gets away with it',
    class: 'Standard',
  },
  {
    id: 1,
    name: 'Felicity',
    location: 'Tegucigalpa, Honduras',
    description:
      "Felicity is 19, small and delicate-featured. Pretty in a flawed, accessible way. She doesn't stop the party when she walks in, butyou'd like to get to know her.",
    class: 'Standard',
  },
  {
    id: 2,
    name: 'CP 5-29',
    location: 'Valletta, Malta',
    description:
      "Friends call him Dessy. He has many abilities and he's armed with self-defense tech. He often takes jobs in casinos just for fun, without any compensation.",
    class: 'Standard',
  },
  {
    id: 3,
    name: 'Nenita',
    location: 'Manila, Philippines',
    description:
      'One day she could be president because of her successful boss-level accomplihsment, but that is if she could just find her car keys.',
    class: 'Standard',
  },
  {
    id: 4,
    name: 'Xander',
    location: 'Washington D.C., USA',
    description:
      "There's something about him, perhaps it's a feeling of remorse or perhaps it's simply his sympathy. Nonetheless, people tend to welcome him, while commending him for his deeds.",
    class: 'Standard',
  },
  {
    id: 5,
    name: 'Saho',
    location: 'Eastern, Land of Boo',
    description:
      'His family was taken when their village was invaded by an enemy tribe. As a samurai, he vowed to find his loved ones, even if that means making the ultimate sacrifice.',
    class: 'Rare',
  },
  {
    id: 6,
    name: 'C 7-14',
    location: 'Z City, 2048',
    description:
      "She moves, behaves, and thinks as a human would, but her creator, who has kept her isolated since she was built, does not think she's human enough to have replaced his deceased daughter, since she can transform to any human as well.",
    class: 'Rare',
  },
  {
    id: 7,
    name: 'Err',
    location: 'Unknown',
    description:
      'Nobody knows where he came from, or what he is. He thinks like a 4-year-old and has incredible strength. Fun fact: He glows in the dark, some say he is radioactive.',
    class: 'Rare',
  },
  {
    id: 8,
    name: 'Dakato',
    location: 'Unknown',
    description:
      'Dakato always knew there was something different about him, where he always was lucky, others around him faltered. In the past months, Andrews, Asmodeus, and Markus have all bribed or threatened him to their sides, but he remains indecisive.',
    class: 'Rare',
  },
  {
    id: 9,
    name: 'Ignite',
    location: 'Unknown',
    description:
      "The prince of fire comes from the family of Inferno tree. He is the descent of all lavas and magmas and can reach up to the temperature of the Earth's core. He may look puny and weak, but he has the power to bring flames within the palm of his hands.",
    class: 'Ultra Rare',
  },
  {
    id: 10,
    name: 'Pluto',
    location: 'Unknown',
    description:
      'This God metabolism enables it to endure long periods on the cold surface, harnessing the power to become a God of solid ice! No other lifeforms can withstand such freeze temperature, especially his rampage ice effects.',
    class: 'Ultra Rare',
  },
  {
    id: 11,
    name: 'VanVan',
    location: 'Unknown',
    description:
      "Able to create, shape and manipulate physic air, intense wind, or windstorm. This allows her to blow away the thoughts of a person's mind, and change it to whatever she wants it to be.",
    class: 'Ultra Rare',
  },
  {
    id: 12,
    name: 'Charge',
    location: 'Unknown',
    description:
      "Can manipulate the shockwaves caused by lightning during a storm, known as thunder. To deal with deadly and possibly concussive results. He can release thunder energy and the soundwave can destroy a person's eardrums resulting in the deaf.",
    class: 'Ultra Rare',
  },
  {
    id: 13,
    name: 'Serpent',
    location: 'Unknown',
    description:
      'Serpent fights for peace between the underwater world and the land world. The last time the land world heard about the underwater world on land was during the Atlantis Era.',
    class: 'Ultra Rare',
  },
  {
    id: 14,
    name: 'Elon Mustard',
    location: 'Polker Labs, Malta',
    description:
      'Elon Mustard, tech visionary and now the iconic PKR dealer, brings his blend of innovation and strategy to the Polker tables, guiding players through rounds with sharp wit and unexpected twists.',
    class: 'Common',
  },
];
