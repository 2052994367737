import { STORE } from 'store';
import React, { useState, useContext } from 'react';
import {
  Link,
  Modal,
  Button,
  Backdrop,
  IconButton,
  Icon,
  Typography,
  FormControl,
  InputAdornment,
  OutlinedInput,
} from '@material-ui/core';
import useStyles from './styles';
import { NFT } from 'constants/nft';
import CloseIcon from '@material-ui/icons/Close';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSetStoreValue } from 'react-context-hook';
import { P2E_API } from 'apis/P2E/p2e';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { MuiThemeProvider, useTheme } from '@material-ui/core/styles';
import Loading from 'components/Loading';

const ViewNFT = props => {
  const { open, setOpen, dealer, refetchDealerList } = props;
  // eslint-disable-next-line no-undef
  const nodeEnv = process.env.REACT_APP_ENV;
  const [displayModal, setDisplayModal] = useState(false);
  const [showWithdrawNFT, setShowWithdrawNFT] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isWithdrawn, setIsWithdrawn] = useState(false);
  const [hash, setHash] = useState('');
  const setSnackbar = useSetStoreValue(STORE.SNACKBAR);
  const [values, setValues] = React.useState({
    address: '',
  });
  const classes = useStyles();
  const theme = useTheme();

  // eslint-disable-next-line no-undef
  const contract = process.env.REACT_APP_POLYGON_DEALERS_SMART_CONTRACT_ADDRESS;
  // remove BETA_ from dealer name and make it sentence case
  let dealerName = dealer.name?.replace('BETA_', '');
  if (dealerName?.startsWith('ELON_')) {
    dealerName = 'Elon Mustard';
  }
  const nft = NFT.find(item => item.name === dealerName);
  const tokenId = dealer.id;
  const explorerUrl =
    nodeEnv === 'production'
      ? 'https://polygonscan.com/token/'
      : 'https://amoy.polygonscan.com/token/';

  const handleClose = () => {
    setOpen(false);
    setIsSubmitted(false);
    setIsWithdrawn(false);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setIsSubmitted(false);
    setIsWithdrawn(false);
    setDisplayModal(false);
    setShowWithdrawNFT(false);
  };

  const handleDisplayModal = () => {
    setDisplayModal(!displayModal);
  };

  const handleShowWithdrawNFT = () => {
    setShowWithdrawNFT(!showWithdrawNFT);
    setValues({ ...values, address: '' });
  };

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const onSubmit = async () => {
    try {
      setDisplayModal(false);
      setIsSubmitted(true);
      setLoading(true);
      setHash(null);
      const payload = {
        uuid: dealer.uuid,
        address: values.address,
      };
      const res = await P2E_API.withdrawNft(payload);
      setHash(res.transaction_hash);
      await refetchDealerList();
      setLoading(false);
      setIsWithdrawn(true);
      setHash(res.transaction_hash);
      setSnackbar({
        variant: 'success',
        message: 'Success withdrawing NFT!',
      });
    } catch (e) {
      setSnackbar({
        variant: 'error',
        message: 'Failed withdrawing NFT!',
      });
      setLoading(false);
    }
  };

  const renderHash = () => {
    if (nodeEnv === 'production') {
      return (
        <Typography className={classes.hash} color="textPrimary">
          You can check if the transaction is done{' '}
          <a
            href={`https://polygonscan.com/tx/${hash}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            here
          </a>
          .
        </Typography>
      );
    }

    return (
      <Typography className={classes.hash} color="textPrimary">
        You can check if the transaction is done{' '}
        <a
          href={`https://mumbai.polygonscan.com/tx/${hash}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          here
        </a>
        .
      </Typography>
    );
  };

  const renderSuccessWithdrawNFT = () => {
    return (
      <Modal
        hideBackdrop
        className={classes.modal}
        open={showWithdrawNFT}
        onClose={handleShowWithdrawNFT}
      >
        <Backdrop className={classes.backdrop} open={showWithdrawNFT}>
          <div
            className={clsx(classes.withdrawContainer, classes.verticalCenter)}
          >
            <Typography variant="h6" className={classes.withdrawHeader}>
              Withdraw POLKER NFT (Polygon Network)
            </Typography>
            <div className={classes.imageRoot}>
              <LazyLoadImage
                className={classes.withdrawImage}
                effect="blur"
                visibleByDefault
                src={dealer.image}
              />
            </div>
            <div className={classes.withdrawContent}>
              <Typography className={classes.name}>
                NFT will be transferred to:
              </Typography>
              <Typography>{values.address}</Typography>
              <Button
                variant="outlined"
                className={clsx(
                  classes.withdrawButton,
                  classes.cancelWithdrawButton,
                  classes.closeWithdrawButton,
                )}
                onClick={handleCloseModal}
              >
                Close
              </Button>
            </div>
          </div>
        </Backdrop>
      </Modal>
    );
  };

  const renderSubmittedWithdrawNFT = () => {
    return (
      <Modal
        hideBackdrop
        className={classes.modal}
        open={showWithdrawNFT}
        onClose={handleShowWithdrawNFT}
      >
        <Backdrop className={classes.backdrop} open={showWithdrawNFT}>
          <div
            className={clsx(classes.withdrawContainer, classes.verticalCenter)}
          >
            {renderConfirmWithdraw()}
            <Typography variant="h6" className={classes.withdrawHeader}>
              Withdraw POLKER NFT (Polygon Network)
            </Typography>
            <div className={classes.imageRoot}>
              <LazyLoadImage
                className={classes.withdrawImage}
                effect="blur"
                visibleByDefault
                src={dealer.image}
              />
            </div>
            <div className={classes.withdrawContent}>
              <Typography className={classes.name}>Sending to:</Typography>
              <div className={classes.addressRoot}>
                <Typography>{values.address}</Typography>
                <Loading iconOnly />
              </div>
              <Loading className={classes.bottomLoader} determinate size={80} />
            </div>
          </div>
        </Backdrop>
      </Modal>
    );
  };

  const renderWithdrawNotice = () => {
    return (
      <div className={classes.noticeRoot}>
        <Typography variant="subtitle1">
          Only Withdraw Polker NFT to a Polygon Network Address.
        </Typography>
        <Typography className={classes.subtitle} variant="subtitle2">
          You are withdrawing Polker NFTs on a Polygon network.Verify Wallet
          address matches to the withdraw address.
        </Typography>
      </div>
    );
  };

  const renderConfirmWithdraw = () => {
    if (!displayModal) {
      return null;
    }

    return (
      <Modal
        className={classes.modal}
        open={displayModal}
        onClose={handleDisplayModal}
      >
        <Backdrop className={classes.backdrop} open={displayModal}>
          <div
            className={clsx(
              classes.withdrawContainer,
              classes.verticalCenter,
              classes.withdrawConfirmModal,
            )}
          >
            <Typography variant="body1" color="textPrimary">
              Are you sure you want to withdraw?
            </Typography>
            <div className={classes.buttonContainer}>
              <Button
                className={clsx(
                  classes.withdrawButton,
                  classes.cancelWithdrawButton,
                  classes.withdrawConfirmButton,
                )}
                onClick={handleDisplayModal}
              >
                Cancel
              </Button>
              <Button
                className={clsx(
                  classes.withdrawButton,
                  classes.withdrawConfirmButton,
                )}
                onClick={onSubmit}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Backdrop>
      </Modal>
    );
  };

  const renderWithdrawNFT = () => {
    if (!showWithdrawNFT) {
      return null;
    }

    if (isSubmitted && loading) {
      return renderSubmittedWithdrawNFT();
    }

    if (isSubmitted && isWithdrawn) {
      return renderSuccessWithdrawNFT();
    }

    return (
      <Modal
        hideBackdrop
        className={classes.modal}
        open={showWithdrawNFT}
        onClose={handleShowWithdrawNFT}
      >
        <Backdrop className={classes.backdrop} open={showWithdrawNFT}>
          <div
            className={clsx(classes.withdrawContainer, classes.verticalCenter)}
          >
            {renderConfirmWithdraw()}
            <Typography variant="h6" className={classes.withdrawHeader}>
              Withdraw POLKER NFT (Polygon Network)
            </Typography>
            <div className={classes.imageRoot}>
              <LazyLoadImage
                className={classes.withdrawImage}
                effect="blur"
                visibleByDefault
                src={dealer.image}
              />
            </div>
            <div className={classes.withdrawContent}>
              <Typography className={classes.name}>
                Polker NFT Wallet Address:
              </Typography>
              <MuiThemeProvider theme={theme}>
                <FormControl
                  className={classes.formField}
                  fullWidth
                  variant="standard"
                >
                  <OutlinedInput
                    type="text"
                    value={values.address}
                    className={classes.field}
                    onChange={handleChange('address')}
                    endAdornment={
                      <InputAdornment position="end">
                        <Icon edge="end">
                          {values.address ? (
                            <CheckCircleIcon className={classes.icon} />
                          ) : null}
                        </Icon>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </MuiThemeProvider>
              {renderWithdrawNotice()}
              <div className={classes.buttonContainer}>
                <Button
                  variant="outlined"
                  className={clsx(
                    classes.withdrawButton,
                    classes.cancelWithdrawButton,
                  )}
                  onClick={handleShowWithdrawNFT}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  className={classes.withdrawButton}
                  onClick={handleDisplayModal}
                  disabled={values.address === ''}
                >
                  Withdraw
                </Button>
              </div>
            </div>
          </div>
        </Backdrop>
      </Modal>
    );
  };

  return (
    <Modal
      hideBackdrop
      className={classes.modal}
      open={open}
      onClose={handleClose}
    >
      <Backdrop className={classes.backdrop} open>
        <IconButton
          color="secondary"
          onClick={handleClose}
          className={classes.closeButton}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
        {renderWithdrawNFT()}
        <div className={classes.container}>
          <div className={classes.imageRoot}>
            <LazyLoadImage
              className={classes.image}
              effect="blur"
              visibleByDefault
              src={dealer.image}
            />
          </div>
          <div className={classes.content}>
            <Typography variant="h6" className={classes.name}>
              {dealer.name}
            </Typography>
            <Typography variant="body1" className={classes.location}>
              {nft?.location}
            </Typography>
            <Typography variant="body1" className={classes.description}>
              {nft?.description}
            </Typography>
            <div className={classes.attribute}>
              {nft?.class === 'Standard' && (
                <div className={classes.card}>
                  <Typography className={classes.attributeName}>
                    Standard
                  </Typography>
                  <img src="./images/S.png" />
                </div>
              )}
              {nft?.class === 'Rare' && (
                <div className={classes.card}>
                  <Typography className={classes.attributeName}>
                    Rare
                  </Typography>
                  <img src="./images/R.png" />
                </div>
              )}
              {nft?.class === 'Ultra Rare' && (
                <div className={classes.card}>
                  <Typography className={classes.attributeName}>
                    Ultra Rare
                  </Typography>
                  <img src="./images/UR.png" />
                </div>
              )}
              <div className={classes.card}>
                <Typography className={classes.attributeName}>Level</Typography>
                <div className={classes.level}>
                  <Typography variant="h5">1</Typography>
                </div>
              </div>
            </div>
            <div className={classes.row}>
              <Typography variant="body1" className={classes.title}>
                Token ID:
              </Typography>
              <Typography variant="body1">{tokenId}</Typography>
            </div>
            <div className={classes.row}>
              <Typography variant="body1" className={classes.title}>
                Token Standard:
              </Typography>
              <Typography variant="body1">ERC-1155</Typography>
            </div>
            <div className={classes.row}>
              <Typography variant="body1" className={classes.title}>
                Blockchain:
              </Typography>
              <Typography variant="body1">Polygon</Typography>
            </div>
            <div className={classes.row}>
              <Typography variant="body1" className={classes.title}>
                Contract Address:
              </Typography>
              <Link
                href={`${explorerUrl}${contract}?a=${tokenId}`}
                underline="none"
                target="_blank"
              >
                <Typography variant="body1">{contract}</Typography>
              </Link>
            </div>
            <Button
              variant="outlined"
              className={classes.withdrawButton}
              onClick={handleShowWithdrawNFT}
            >
              Withdraw
            </Button>
          </div>
        </div>
      </Backdrop>
    </Modal>
  );
};

export default ViewNFT;
