import { makeStyles } from '@material-ui/styles';
import { wrap } from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    padding: theme.spacing(4),
  },
  closeButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    position: 'absolute',
    top: 16,
    right: 24,
    zIndex: 69420,
  },
  '@media (max-width: 960px)': {
    withdrawContainer: {
      overflow: 'auto',
      flexWrap: 'nowrap',
    },
  },
  '@media (max-width: 610px)': {
    withdrawContainer: {
      height: '100%',
    },
  },
  container: {
    padding: 24,
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    position: 'absolute',
    justifyContent: 'center',
    top: 0,
    // [theme.breakpoints.down('lg')]: {
    //   position: 'absolute',
    //   top: 0,
    // },
  },
  image: {
    width: '100%',
    maxWidth: 400,
    objectFit: 'contain',
  },
  details: {
    paddingTop: theme.spacing(4),
  },
  modal: {
    height: '100vh',
    // marginLeft: 200,
    position: 'relative',
    zIndex: 0,
    [theme.breakpoints.up('md')]: {
      marginLeft: 200,
    },
  },
  backdrop: {
    position: 'absolute',
    overflow: 'auto',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  content: {
    flex: 1,
    width: '100%',
    maxWidth: 600,
    flexDirection: 'column',
    color: 'white',
  },
  item: {
    height: '100%',
  },
  name: {
    fontWeight: 500,
    marginBottom: 10,
  },
  location: {
    fontWeight: 500,
    marginBottom: 20,
  },
  attribute: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    textAlign: 'center',
    marginRight: 20,
    width: 150,
    height: 160,
    padding: 20,
    borderRadius: 30,
    backgroundColor: '#232323',
    marginBottom: 40,
    '& img': {
      width: '80px',
      height: 'auto',
    },
  },
  attributeName: {
    fontWeight: 600,
    textTransform: 'uppercase',
    width: '100%',
  },
  level: {
    height: 80,
    width: 80,
    borderRadius: '50%',
    border: '2px solid #E6007A',
    color: '#E6007A',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  count: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'baseline',
    marginBottom: 20,
  },
  row: {
    display: 'flex',
    wordBreak: 'break-word',
  },
  title: {
    minWidth: 180,
  },
  description: {
    width: '100%',
  },
  imageRoot: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 28,
  },
  withdrawButton: {
    height: 44,
    marginLeft: 8,
    borderColor: theme.palette.primary.main,
    border: '2px solid',
    borderRadius: 30,
    width: 160,
    fontWeight: '800',
    marginTop: 40,
    marginBottom: 60,
  },
  withdrawContainer: {
    backgroundColor: '#272727',
    borderRadius: 20,
    flexDirection: 'column',
    padding: 40,
    display: 'flex',
  },
  withdrawHeader: {
    textAlign: 'center',
    color: theme.palette.white,
    fontWeight: 600,
    marginBottom: 20,
  },
  withdrawImage: {
    maxWidth: 144,
    objectFit: 'contain',
  },
  verticalCenter: {
    margin: 0,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  withdrawContent: {
    color: theme.palette.white,
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingLeft: 40,
    paddingRight: 40,
  },
  cancelWithdrawButton: {
    borderColor: theme.palette.white,
  },
  formField: {
    backgroundColor: theme.palette.black,
    borderRadius: 8,
    color: theme.palette.primary.text,
  },
  field: {
    borderRadius: 8,
  },
  icon: {
    color: theme.palette.button.main,
  },
  noticeRoot: {
    marginTop: 20,
    backgroundColor: '#4A4A4A',
    borderRadius: 8,
    maxWidth: 596,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  subtitle: {
    marginTop: 8,
  },
  addressRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bottomLoader: {
    marginTop: 20,
  },
  iconRoot: {
    display: 'flex',
    justifyContent: 'center',
  },
  checkIcon: {
    color: '#1C8EF6',
    fontSize: 24,
    marginTop: 20,
  },
  hash: {
    textAlign: 'center',
    fontSize: 12,
  },
  closeWithdrawButton: {
    marginLeft: 0,
    marginTop: 12,
  },
  withdrawConfirmModal: {
    justifyContent: 'center',
    padding: 28,
    textAlign: 'center',
  },
  accessoryCard: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    textAlign: 'center',
    marginRight: 20,
    width: 150,
    height: 160,
    padding: 20,
    borderRadius: 30,
    backgroundColor: '#232323',
    marginBottom: 40,
    position: 'relative',
    cursor: 'pointer',
  },
  accessoryDetails: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  accessoryList: {
    flex: 1,
    backgroundColor: '#232323',
    color: '#FFFFFF',
    height: 724,
    width: 600,
    borderRadius: 30,
    marginTop: 108,
    marginBottom: 44,
    maxWidth: 600,
    padding: 44,
    overflow: 'auto',
  },
  accessoryDesc: {
    marginBottom: 14,
    textAlign: 'center',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  accessoryIcon: {
    height: 28,
    marginBottom: 4,
  },
}));

export default useStyles;
